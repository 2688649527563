$primary: #008F85;
$secondary: #004251;
$gray: #E6E6E6;
$orange: #dc8d0e;

$green: #0D9A34;
$yellow: #FFC000;
$danger: #CD2C2C;
$gray-300: #808080;

$dark-bg: rgb(33, 33, 33);

.trip-modal-form {
  height: 100%;
  width: 97%;

  .trip-modal-destinations {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;

    .trip-modal-list {
      position: absolute;
      background-color: white;
      z-index: 2;
      top: 63px;
      width: 101%;
      max-height: 30vh;
      overflow-y: auto;
      border: 1px solid $gray;

      &.dark {
        background-color: $dark-bg;
      }
    }
  }

  .trip-modal-text-field {
    margin-top: 15px;
  }
}

.vessel-warning {
  color: $orange;
  font-size: 12px;
  font-weight: 500;
}

.mui-textarea {
  width: 100%;
  padding: 12px;
  border-radius: 4px 4px 0 4px;
  border: 1px solid $gray;

  &:hover {
    border-color: $secondary;
  }

  &:focus {
    outline-color: $primary;
  }
}

.reason-card {
  min-height: 100px;
  background-color: rgba($color: $green, $alpha: 0.2);
  border-radius: 4px;
  border: 3px solid $green;
  display: flex;
  align-items: center;

  &.INFO { 
    border-color: $green;
    background-color: rgba($color: $green, $alpha: 0.2);
  }
  &.WARNING { 
    border-color: $yellow;
    background-color: rgba($color: $yellow, $alpha: 0.2);
  }
  &.DANGER { 
    border-color: $danger;
    background-color: rgba($color: $danger, $alpha: 0.2);
  }
  &.UNKNOWN { 
    border-color: $gray-300;
    background-color: rgba($color: $gray-300, $alpha: 0.2);
  }
}