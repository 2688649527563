$primary: #008F85;
$secondary: #004251;
$dark: #000A18;
$gray-100: #F8F9FA;
$gray-200: #E6E6E6;
$gray-300: #bfbfbf;
$yellow: #FFC000;
$orange: #dc8d0e;
$danger: #CD2C2C;
$darkRed: #500200;
$black: #121212;
$white: white;
$blue: #00B0FF;
$darkBlue: #1B2D5B;
$green: #0D9A34;
$theme-dark-gray: #212529;

// change bootstrap default theme
$theme-colors: (
  "primary": $primary,
  'secondary': $secondary,
  "danger": $danger,
  'dark': $dark,
  'gray-100': $gray-100,
  'gray-200': $gray-200,
  'gray-300': $gray-300,
  "yellow": $yellow,
  'dark-red': $darkRed,
  'info': $blue,
  'dark-blue': $darkBlue,
  'black': $black,
  'white': $white,
);

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

body {
  min-width: 870px;
  overflow-x: auto;
}

::-webkit-scrollbar {
  height: 0;
  width: 8px;
}

::-webkit-scrollbar-thumb {
  height: 10px;
  background: #e1e7ed;
  -webkit-border-radius: 1ex;
}

// Center a component in a div
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

// Center a component vertically in a div
.center-y {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
  
// Add shadow below the component
.shadow {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
}


// Pointer cursor
.pointer {
  cursor: pointer;
}

// Cards that show summary of a data
.summary-cards {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 15px;
}

// General cards. Ex: tables
.card {
  border: none;
  border-radius: 10px;
  padding: 0 0.5rem;
  margin-top: 10px;

  &.login-dark {
    background-color: $theme-dark-gray;
    color: white;
  }

  &.login {
    cursor: initial;
  }
}

.loading-img {
  position: absolute;
  z-index: 1;
  display: flex;
  mix-blend-mode: multiply;
  top: 30%;
  left: 35%;
}

.esri-view .esri-view-surface {
  &::after {
    outline: none !important;
  }
}

.trip-modal-eta {
  position: absolute;
  text-align: center;
  color: white;
  font-weight: bold;
  width: 100%;
  z-index: 2;
  bottom: 0px;
  right: 0px;
  background-color: $primary;
  padding: 10px 0px;
  box-shadow: 0px -4px 5px rgb(0 0 0/15%);
}

.beta-agreement-text {
  cursor: pointer;
  color: $primary;

  &:hover {
      color: $secondary
  }
}