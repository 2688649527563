$primary: #008F85;
$secondary: #004251;
$unknown-gray: #808080;
$info-green: #0D9A34;
$warning-yellow: #FFC000;
$danger-red: #CD2C2C;
$colorBlind-blue: #576ffc;
$colorBlind-orange: #ffc500;
$colorBlind-red: #d10000;

.trip-alerts {
  height: calc(100% - 139px);
  width: calc(100% + 12px);
  overflow-y: auto;
  .trip-alert-card {
    padding: 20px 20px;
    margin: 15px 5px 15px 0;
    border-radius: 4px;
    border-left: 5px solid $unknown-gray;
    transition: 0.1s ease-in-out all;

    cursor: pointer;

    &.INFO {
      background-color: rgba($color: $info-green, $alpha: 0.2);
      border-color: $info-green;
      &.colorBlind {
        background-color: rgba($color: $colorBlind-blue, $alpha: 0.2);
        border-color: $colorBlind-blue;
      }
    }
    &.WARNING {
      background-color: rgba($color: $warning-yellow, $alpha: 0.2);
      border-color: $warning-yellow;
      &.colorBlind {
        background-color: rgba($color: $colorBlind-orange, $alpha: 0.2);
        border-color: $colorBlind-orange;
      }
    }
    &.DANGER {
      background-color: rgba($color: $danger-red, $alpha: 0.2);
      border-color: $danger-red;
      &.colorBlind {
        background-color: rgba($color: $colorBlind-red, $alpha: 0.2);
        border-color: $colorBlind-red;
      }
    }
    &.UNKNOWN {
      background-color: rgba($color: $unknown-gray, $alpha: 0.2);
      border-color: $unknown-gray;
    }

    &:hover {
      outline: 5px solid $unknown-gray;
      
      &.INFO {
        outline-color: $info-green;
        &.colorBlind {
          outline-color: $colorBlind-blue
        }
      }
      &.WARNING {
        outline-color: $warning-yellow;
        &.colorBlind {
          outline-color: $colorBlind-orange
        }
      }
      &.DANGER {
        outline-color: $danger-red;
        &.colorBlind {
          outline-color: $colorBlind-red
        }
      }
      &.UNKNOWN {outline-color: $unknown-gray;}
    }

    &.card-selected {
      outline: 5px solid $unknown-gray;
      
      &.INFO {
        outline-color: $info-green;
        &.colorBlind {
          outline-color: $colorBlind-blue
        }
      }
      &.WARNING {
        outline-color: $warning-yellow;
        &.colorBlind {
          outline-color: $colorBlind-orange
        }
      }
      &.DANGER {
        outline-color: $danger-red;
        &.colorBlind {
          outline-color: $colorBlind-red
        }
      }
      &.UNKNOWN {outline-color: $unknown-gray;}
    }

    .trip-alert-icon {
      font-size: 24px;
      margin-right: 18px;

      &.INFO {
        color: $info-green;
        &.colorBlind {
          color: $colorBlind-blue
        }
      }
      &.WARNING {
        color: $warning-yellow;
        &.colorBlind {
          color: $colorBlind-orange
        }
      }
      &.DANGER {
        color: $danger-red;
        &.colorBlind {
          color: $colorBlind-red
        }
      }
      &.UNKNOWN {
        color: $unknown-gray;
      }
    }

    .trip-alert-msg {
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      margin-bottom: 0;

      &.read-clicked {
        display: inline;
      }
    }

    .trip-alert-msi-read {
      text-align: right;
      text-decoration: underline;

      .trip-alert-msi-read-word {
        display: inline;
        &:hover {
          font-weight: bold;
        }
      }

      &.no-chart {
        cursor: default;

        &:hover {
          font-weight: normal;
        }
      }
    }
  }
}

.refresh {
  cursor: pointer;

  &.spin {
    animation-name: spin;
    animation-duration: 1000ms;
    animation-iteration-count: 1;
    animation-timing-function: ease; 
  }
}

@keyframes spin {
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(360deg);
  }
}