$dark-bg: rgb(33, 33, 33);
$primary: #008F85;
$secondary: #004251;
$gray-200: #E6E6E6;
$gray-300: #bfbfbf;
$dark-gray: #212121;
$dark-gray-hover: #3e3e3e;

$unknown-gray: #9C9C9C;
$unknown-gray-dark: #505050;
$info-green: #58AD61;
$info-green-dark: #2b6d39;
$warning-yellow: #FACB3C;
$warning-yellow-dark: #dd9000;
$danger-red: #D35B5C;
$danger-red-dark: #520100;

$colorBlind-red: #d10000;
$colorBlind-red-dark: #4b0200;
$colorBlind-blue: #576ffc;
$colorBlind-blue-dark: #003c76;
$colorBlind-orange: #ffc500;
$colorBlind-orange-dark: #7e4f00;
$colorBlind-primary: #19168c;

.current-position {
  position: absolute;
  bottom: 104px;
  right: 15px;
  width: 32px;
  height: 32px;
  background-color: white;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  
  &:hover {
    background-color: #f2f2f2;
  }
}

.poi {
  position: absolute;
  // top: 20px;
  top: 40px; // remove this when beta version is done
  left: 85px;
  background-color: white;
  border-radius: 4px;
  width: 300px;
  display: flex;

  &.dark {
    background-color: $dark-bg;
  }

}

.offline {
  position: absolute;
  top: 55px;
  left: 400px;
}

.esri-popup__action-text {
  display: inline !important;
}

.map-btn-toggle {
  position: absolute;
  // top: 20px;
  top: 40px; // remove this when beta version is done
  left: 20px;
  width: 50px;
  height: 50px;
  padding: 27.5px;
  background-color: white;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $gray-300;
  font-size: 18px;
  cursor: pointer;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  transition: all ease 0.2s;

  &:hover {
    background-color: $gray-300;
    color: white;
  }

  &.color-blind-toggle{
    &:hover {
      background-color: $colorBlind-primary;
      color: white;
    }
  }

  &.dark {
    background-color: $dark-gray;
    box-shadow: 0 1px 2px rgba($color: white, $alpha: 0.3);
  }
}

.map-btn {
  position: absolute;
  // top: 80px;  // enable this when beta version is done
  top: 100px; // remove this when beta version is done
  left: 20px;
  background-color: white;
  border-radius: 4px;
  padding: 10px 0;
  transition: all ease-in-out 0.3s;
  max-height: 80%;
  overflow-y: auto;

  &.dark {
    background-color: $dark-gray;
    color: white;
    .map-layer-btn-div {
      &:hover {
        background-color: $dark-gray-hover;
      }
    }
  }

  &.show {
    left: -200px;
  }

  .map-layers-alert-div {
    &.disabled {
      opacity: 0.4;
      pointer-events: none;
    }
  }

  .map-layer-btn-div {
    display: flex;
    align-items: center;
    padding: 6px 15px;
    cursor: pointer;
    transition: all ease 0.2s;

    &:hover {
      background-color: $gray-200;
    }

    &.hide {
      .map-layer-btn-icon, .map-layer-btn-icon-map-pin, .map-layer-btn-icon.mile, .map-layer-btn-icon.msi {
        background-color: $gray-300
      }  
    }

    &.disabled {
      opacity: 0.4;
      pointer-events: none;
    }

    .map-layer-btn-icon {
      width: 15px;
      height: 15px;
      border-radius: 50%;
      padding: 8px;
      background-color: $primary;
      color: white;
      font-size: 10px;
      margin-right: 8px;

      &.mile {
        background-color: #1B2d5b;
      }

      &.msi {
        background-color: #FFC000;
      }
 
    }

    .color-blind-map-layer-btn{
      background-color: $colorBlind-primary;
    }

    .map-layer-btn-icon-map-pin {
      width: 31px;
      height: 31px;
      border-radius: 50%;
      background-color: #8c61c9;
      margin-right: 8px;
      padding: 7px;
    }
  }

  .map-layer-dropdown-label {
    opacity: 0.4;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 10px;
    letter-spacing: 1.1px;
    margin-left: 12px;
  }
}

.beta-banner {
  background-color: $gray-300;
  color: $primary;
  font-weight: 600;
  font-size: 14px;
  padding: 2px 10px;

  &.dark {
    background-color: $dark-gray;
  }
}

.map-legend {
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  padding-left: 20px;

  .map-legend-color {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid $unknown-gray-dark;
    color: white;
    font-weight: bold;
    padding-bottom: 2px;
    font-size: 20px;
    margin-right: 5px;
    &.green {
      background-color: $info-green;
      border-color: $info-green-dark;
      
      &.color-blind {
        background-color: $colorBlind-blue;
        border-color: $colorBlind-blue-dark;
      }
    }
    &.yellow {
      background-color: $warning-yellow;
      border-color: $warning-yellow-dark;
      
      &.color-blind {
        background-color: $colorBlind-orange;
        border-color: $colorBlind-orange-dark;
      }
    }
    &.red {
      background-color: $danger-red;
      border-color: $danger-red-dark;
      
      &.color-blind {
        background-color: $colorBlind-red;
        border-color: $colorBlind-red-dark;
      }
    }
    &.unknown {
      background-color: $unknown-gray;
      border-color: $unknown-gray-dark;
    }
    &.white {
      color: $unknown-gray;
      background-color: white;
      border-color: $unknown-gray-dark;
    }
  }
}