$primary: #008F85;
$secondary: #004251;

.beta-agreement-form {
  width: 60%;
  max-height: 80vh;
  overflow-y: scroll;
  background-color: white;
  border-radius: 10px;
  padding: 20px 30px;
}