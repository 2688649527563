$primary: #008F85;
$secondary: #004251;

.login-img {
    position: absolute;
    width: 100vw;
    height: 100vh;
    left: 0px;
    top: 0px;
    z-index: 2;
    background: linear-gradient(116.82deg, rgba(0, 143, 133, 0.7) 0%, rgba(0, 66, 81, 0.7) 50%, rgba(0, 10, 24, 0.7) 100%);
    &.darkmode {
        background: linear-gradient(116.82deg, rgba(0, 143, 133, 0.5) 0%, rgba(0, 66, 81, 0.5) 50%, rgba(0, 10, 24, 0.5) 100%);
    }
}

.login-bg {
    width: 100vw;
    height: 100vh;
    background-image: url("../../images/map-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    &.darkmode {
        background-image: url('../../images//map-bg-dark.png')
    }
}

.MuiFormControlLabel-asterisk {
    box-sizing: initial !important;
    margin-top: -30px;
    margin-left: 7px;
}