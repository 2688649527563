$primary: #008F85;
$secondary: #004251;
$theme-dark-gray: #212529;
$dark: #000A18;
$colorBlind-primary: #19168c;

.submenu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0 5px 5px;
  color: white;

  &.info {
    padding-right: 20px;
  }

  span {
    margin: 0 20px 0 5px;
    
    .logout {
      margin: 0; 
    }
  }
}

.nav-icons {
  color: $primary;

  &.active {
    color: $secondary;
  }
  &.color-blind-nav-btn.active{
    color: $colorBlind-primary;
  }
}

.dark-mode {
  .nav-icons {
    &.active {
      color: white;
    }
  }
}


.popup-modal {
  .popup-modal-content {
    background-color: rgba($color: white, $alpha: 0.85);
    width: 40vw;
    min-width: 500px;
    border-radius: 10px;

    &.dark {
      background-color: $dark;
    }
    
    .popup-modal-header {
      background-color: $primary;
      color: white;
      padding: 20px 30px;
      border-radius: 10px 10px 0 0;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .popup-modal-x {
        cursor: pointer;
        transition: all ease 0.2s;
        
        &:hover {
          color: $secondary;
        }
      }
    }

    .popup-modal-form {
      padding: 20px 8%;
      overflow-y: auto;
      max-height: 90vh;

      .popup-modal-card {
        border-radius: 10px;
        padding: 20px;
        margin-bottom: 20px;
        background-color: white;
        transition: all ease 0.2s;

        &.dark {
          background-color: $theme-dark-gray;
          color: white;
        }

        &:hover {
          box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);

        }

        .popup-modal-rating {
          display: flex;
          align-items: center;

          small { width: 180px}
        }

        .popup-modal-textfield {
          &.dark {
            background-color: $theme-dark-gray;
            color: white;
          }
        }

        &.half {
          width: 48%;
          min-width: 200px;
        }
      }
    }
  }
}